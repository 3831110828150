'use client';

import FormWizardLoader from '@/components/_layout/loaders/FormWizardLoader';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import styles from './blankLayout.module.scss';

type Props = {
  children: React.ReactNode;
  floatingWidgetChildren?: React.ReactNode;
  hasIcon?: boolean;
};

/**
 * Layout: Blank Layout (404 and 500)
 */
function BlankLayout({ children, floatingWidgetChildren, hasIcon }: Props) {
  const [isLoading, setIsloading] = useState<boolean>(true);

  const router = useRouter();

  useEffect(() => {
    setIsloading(false);
  }, []);

  if (isLoading) return <FormWizardLoader />;

  return (
    <div className={styles.blankLayout}>
      <div className={styles.message}>
        {hasIcon && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path d="M15.996 0C7.16251 0 0 7.16254 0 16.0039C0 24.8453 7.16251 32 15.996 32C24.8296 32 32 24.8375 32 16.0039C32 7.17038 24.8374 0 15.996 0ZM22.2172 24.1549L17.9338 19.8166C15.3371 21.4484 11.8617 21.1189 9.6102 18.8517C6.98995 16.2157 7.00563 11.948 9.64157 9.32778C12.2697 6.71537 16.5452 6.73106 19.1655 9.35915C21.417 11.6264 21.7151 15.1174 20.0676 17.7063L24.3511 22.0368L22.2172 24.1549Z" />
            <path d="M18.5458 14.1054C18.5458 16.4197 16.6787 18.2868 14.3801 18.2868C12.0815 18.2868 10.2065 16.4197 10.2065 14.1054C10.2065 11.7911 12.0737 9.9397 14.3801 9.9397C16.6866 9.9397 18.5458 11.8068 18.5458 14.1054Z" />
          </svg>
        )}

        {children}
      </div>
    </div>
  );
}

export default BlankLayout;
